// Fixed size icon
const AddVideo = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="28" viewBox="0 0 38 28" fill="none">
            <g opacity="0.5">
                <path d="M21.0002 16.3327C21.0002 17.4993 21.0002 23.3327 21.0002 23.3327H4.66683V6.99935H11.6668C12.8335 6.99935 12.8335 4.66602 11.6668 4.66602H4.66683C3.3835 4.66602 2.3335 5.71602 2.3335 6.99935V23.3327C2.3335 24.616 3.3835 25.666 4.66683 25.666H21.0002C22.2835 25.666 23.3335 24.616 23.3335 23.3327C23.3335 23.3327 23.3335 17.4993 23.3335 16.3327C23.3335 15.166 21.0002 15.166 21.0002 16.3327Z" fill="#75757F"/>
                <path d="M10.5 19.5V10.5L16.5 15L10.5 19.5Z" fill="#75757F"/>
                <path d="M22.1668 2.33269C22.1668 1.68835 21.6445 1.16602 21.0002 1.16602C20.3558 1.16602 19.8335 1.68835 19.8335 2.33269V11.666C19.8335 12.3104 20.3558 12.8327 21.0002 12.8327C21.6445 12.8327 22.1668 12.3104 22.1668 11.666V2.33269Z" fill="#75757F"/>
                <path d="M25.6668 8.16731C26.3112 8.16731 26.8335 7.64498 26.8335 7.00065C26.8335 6.35632 26.3112 5.83398 25.6668 5.83398L16.3335 5.83398C15.6891 5.83398 15.1668 6.35632 15.1668 7.00065C15.1668 7.64498 15.6891 8.16731 16.3335 8.16731H25.6668Z" fill="#75757F"/>
            </g>
            <path d="M36 1H17C15.8954 1 15 1.89543 15 3V11C15 12.1046 15.8954 13 17 13H36C37.1046 13 38 12.1046 38 11V3C38 1.89543 37.1046 1 36 1Z" fill="#75757F"/>
            <path d="M18.5234 4.34375H19.7734L21.4531 8.45312H21.5234L23.2031 4.34375H24.4531V10H23.4766V6.10938H23.4219L21.8516 9.98438H21.125L19.5547 6.09375H19.5078V10H18.5234V4.34375ZM25.5 10V4.34375H27.625C28.9219 4.34375 29.6133 5.13281 29.6172 6.21875C29.6133 7.30078 28.9141 8.08594 27.6094 8.08594H26.5156V10H25.5ZM26.5156 7.25H27.4688C28.2344 7.24609 28.5781 6.81641 28.5781 6.21875C28.5781 5.61328 28.2344 5.19531 27.4688 5.19531H26.5156V7.25ZM30.2812 8.94531V8.13281L32.7266 4.34375H33.9922V8.10938H34.7344V8.94531H33.9922V10H33.0234V8.94531H30.2812ZM31.3203 8.10938H33.0391V5.5H32.9766L31.3203 8.07031V8.10938Z" fill="white"/>
        </svg>
    );
};

export default AddVideo;
