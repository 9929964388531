const PlayCircle = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12Z" fill="black"/>
            <path d="M12 1C5.92772 1 1 5.92772 1 12C1 18.0723 5.92772 23 12 23C18.0723 23 23 18.0723 23 12C23 5.92772 18.0723 1 12 1Z" stroke="white" strokeOpacity="0.1" strokeWidth="2"/>
            <path d="M10.8 15.8998L15.47 12.3998C15.74 12.1998 15.74 11.7998 15.47 11.5998L10.8 8.09982C10.47 7.84982 10 8.08982 10 8.49982V15.4998C10 15.9098 10.47 16.1498 10.8 15.8998Z" fill="white"/>
        </svg>
    );
};

export default PlayCircle;
