import Popup from '../../components/common/Popup/Popup';
import Button from '../../components/common/Button/Button';
import { Input } from 'antd';
import css from './EditTemplatePopup.module.scss';
import { useCallback, useState, useEffect } from 'react';
import { TemplateRecordType } from '../../features/template/templateSlice';

const EditTemplatePopup = ({
    open,
    onClose,
    onApply,
    record
}: {
    open?: boolean;
    onClose: () => void;
    onApply: (template: TemplateRecordType) => void;
    record?: TemplateRecordType;
}) => {
    const [title, setTitle] = useState(record?.title || '');
    const [brand, setBrand] = useState(record?.brand || '');
    const [closingComment, setClosingComment] = useState(record?.closingComment || '');

    useEffect(() => {
        if (record) {
            setTitle(record.title || '');
            setBrand(record.brand || '');
            setClosingComment(record.closingComment || '');
        }
    }, [record]);

    const cancel = () => {
        onClose();
    };

    const apply = useCallback(() => {
        if (!record) return;

        onApply({
            ...record,
            title,
            brand,
            closingComment
        });
        onClose();
    }, [onClose, onApply, record, title, brand, closingComment]);

    return (
        <Popup
            open={open}
            position="center center"
            onClose={onClose}
        >
            <div className={css.editPopup}>
                <div className={css.content}>
                    <div className={css.title}>{'템플릿 수정'}</div>
                    <div className={css.section}>
                        <div className={css.field}>
                            <div className={css.label}>{'타이틀'}</div>
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className={css.field}>
                            <div className={css.label}>{'브랜드'}</div>
                            <Input
                                value={brand}
                                onChange={(e) => setBrand(e.target.value)}
                            />
                        </div>
                        <div className={css.field}>
                            <div className={css.label}>{'클로징 멘트'}</div>
                            <Input.TextArea
                                value={closingComment}
                                onChange={(e) => setClosingComment(e.target.value)}
                                rows={4}
                            />
                        </div>
                    </div>
                </div>
                <div className={css.buttons}>
                    <Button onClick={cancel} type="line">{'취소'}</Button>
                    <Button onClick={apply}>{'적용'}</Button>
                </div>
            </div>
        </Popup>
    );
};

export default EditTemplatePopup;
