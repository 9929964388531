const Reset = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M10 4.30335V2.89469C10 2.54056 9.57812 2.36742 9.33594 2.61925L7.14844 4.81487C6.99219 4.97226 6.99219 5.21622 7.14844 5.37362L9.32813 7.56924C9.57813 7.81319 10 7.64006 10 7.28593V5.87727C12.5859 5.87727 14.6875 7.99419 14.6875 10.599C14.6875 12.7396 13.2578 14.5496 11.3203 15.1241C10.9922 15.2185 10.7812 15.5333 10.7812 15.8717C10.7812 16.3832 11.2656 16.7845 11.7578 16.635C14.3516 15.8717 16.25 13.4636 16.25 10.599C16.25 7.12067 13.4531 4.30335 10 4.30335Z" fill="currentColor"/>
            <path d="M5.3125 10.599C5.3125 9.54451 5.65625 8.56868 6.24219 7.77385C6.47656 7.45906 6.44531 7.02623 6.17188 6.74293C5.84375 6.4124 5.28125 6.44388 5 6.82162C4.21875 7.87615 3.75 9.18251 3.75 10.599C3.75 13.4636 5.64844 15.8717 8.24219 16.635C8.73438 16.7845 9.21875 16.3832 9.21875 15.8717C9.21875 15.5333 9.00781 15.2185 8.67969 15.1241C6.74219 14.5496 5.3125 12.7396 5.3125 10.599Z" fill="currentColor"/>
        </svg>
    );
};

export default Reset;
