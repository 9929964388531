import { forwardRef, LegacyRef, useEffect, useState, useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';
import type {Placement} from '@floating-ui/react';
import { ko } from "date-fns/locale";
import { format } from "date-fns";
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import TimePicker from '../TimePicker/TimePicker';
import classNames from 'classnames';

const DatePicker = ({
    disabled,
    useMeridiem,
    useTimeSelect,
    placement = 'bottom',
    date,
    wrapperClassName,
    onChange
}: {
    disabled?: boolean;
    useMeridiem?: boolean;
    useTimeSelect?: boolean;
    placement?: Placement | undefined;
    date?: Date;
    wrapperClassName?: string;
    onChange?: (date: Date) => void;
}) => {
    const [current, setCurrent] = useState<Date | undefined>(date);
    const [isOpen, setOpen] = useState(false);

    const updateDate = useCallback((date: Date) => {
        setCurrent(date);
        onChange && onChange(date);
    }, [onChange]);

    useEffect(() => {
        if (date && date !== current) {
            updateDate(date);
        }
    }, [date, current, updateDate]);


    const handleTodayClick = () => {
        updateDate(new Date());
    };

    const handleChange = (date: Date) => {
        updateDate(date);
    };

    const CustomInput = forwardRef((
        {value, onClick}: any,
        ref
    ) => {
        return (
            <div
                className={`datepicker-input ${isOpen ? 'active' : ''} ${useTimeSelect ? 'use-time' : ''} ${useMeridiem ? 'use-meridiem' : ''}`}
                onClick={onClick}
            >
                <input ref={ref as LegacyRef<HTMLInputElement>} value={value} readOnly />
                <Icon size='tiny' selected={isOpen}>{'calendar'}</Icon>
            </div>
        );
    });
    CustomInput.displayName = 'CustomInput';

    const CustomTimeInput = (props: any) => {
        const {value, onChange} = props;

        const _time= value.trim().split(':');
        const handleChange = ({hour, minute}: any) => {
            const newTime = `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
            if (value !== newTime) {

                onChange && onChange(newTime);
            }
        };

        return (
            <div>
                <TimePicker
                    useMeridiem={useMeridiem}
                    hour={parseInt(_time[0])}
                    minute={parseInt(_time[1])}
                    onChange={handleChange}
                />
            </div>
        );
    };

    const renderCustomHeader = ({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
    }: any) => {
        return (
            <div className="header-container">
                <div className="spacer" />
                <div className="year-select">
                    <div onClick={decreaseMonth} className={`previous ${prevMonthButtonDisabled ? 'disabled' : ''}`} />
                    <div className="year-content">{format(date, 'yyyy. MM')}</div>
                    <div onClick={increaseMonth} className={`next ${nextMonthButtonDisabled ? 'disabled' : ''}`} />
                </div>
                <div onClick={() => handleTodayClick()} className="today-button">{'오늘'}</div>
            </div>
        );
    }

    const isToday = (date: Date) => {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    const checkDay = (date: Date) => {
        return isToday(date) ? 'today' : date.getDay() === 0 ? 'sunday' : null; // 0은 일요일을 나타냅니다.
    };

    return (
        <ReactDatePicker
            locale={ko}
            open={isOpen}
            timeFormat={useMeridiem ? 'hh:mm' : 'HH:mm'}
            dateFormat={useTimeSelect ? `yyyy. MM. dd ${useMeridiem ? 'aa hh:mm' : 'H:mm'}` : 'yyyy. MM. dd (eee)'}
            selected={current}
            shouldCloseOnSelect={false}
            popperPlacement={placement}
            formatWeekDay={nameOfDay => nameOfDay.substring(0, 1)}
            customInput={<CustomInput />}
            dayClassName={checkDay}
            renderCustomHeader={renderCustomHeader}
            onChange={handleChange}
            onInputClick={() => setOpen(!isOpen)}
            showPopperArrow={false}
            customTimeInput={<CustomTimeInput />}
            showTimeInput={useTimeSelect}
            wrapperClassName={classNames(wrapperClassName, {'disabled': disabled})}
        >
            <Button fullWidth onClick={() => setOpen(false)}>{'확인'}</Button>
        </ReactDatePicker>
    );
};

export default DatePicker;
