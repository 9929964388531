import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GET, POST } from '../../utils/api';

// 타입 정의
export interface BgmRecordType {
    id: string;
    name: string;
    src: string;
}

interface BgmListType {
    bgmList: BgmRecordType[];
}

// Async Thunk 정의
export const getBgmList = createAsyncThunk('/admin/get-bgm-list', async () => {
    const res = await GET('/admin/get-bgm-list');
    if (!res.errMsg && Array.isArray(res.bgmList) && res.bgmList) {
        return res.bgmList;
    } else {
        return [];
    }
});

// API 함수들
export const addBgm = async (name: string, file: File) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', file);

    return await POST('/admin/add-bgm', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const deleteBgm = async (id: string) => {
    const body = {
        id: id
    };
    return await POST('/admin/delete-bgm', body);
};

// Slice 정의
const initialState: BgmListType = {
    bgmList: []
};

const musicSlice = createSlice({
    name: 'music',
    initialState,
    reducers: {
        setBgmList(state, action: PayloadAction<BgmRecordType[]>) {
            state.bgmList = action.payload;
        },
        reloadBgmList(state) {
            getBgmList();
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBgmList.fulfilled, (state, action) => {
            state.bgmList = action.payload;
        })
    }
});

export const { setBgmList } = musicSlice.actions;
export default musicSlice.reducer;
