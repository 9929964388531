import { Outlet } from 'react-router-dom';
import Menu from '../../Menu/Menu';
import css from './Layout.module.scss';

const Layout = () => {
    return (
        <div className={css.container}>
            <Menu />
            <div className={css.body}>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
