const Library = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M18.4722 4.1665C18.0887 4.1665 17.7778 4.47742 17.7778 4.86095V14.3054C17.7778 14.6889 18.0887 14.9998 18.4722 14.9998C18.8558 14.9998 19.1667 14.6889 19.1667 14.3054V4.86095C19.1667 4.47742 18.8558 4.1665 18.4722 4.1665ZM15 14.3054C15 14.6889 15.3109 14.9998 15.6944 14.9998C16.078 14.9998 16.3889 14.6889 16.3889 14.3054V4.86095C16.3889 4.47742 16.078 4.1665 15.6944 4.1665C15.3109 4.1665 15 4.47742 15 4.86095V14.3054Z" fill="currentColor"/>
            <path d="M12.4998 13.7961V5.37021C12.4998 4.70817 11.9582 4.1665 11.2961 4.1665H2.87021C2.20817 4.1665 1.6665 4.70817 1.6665 5.37021V13.7961C1.6665 14.4582 2.20817 14.9998 2.87021 14.9998H11.2961C11.9582 14.9998 12.4998 14.4582 12.4998 13.7961ZM5.21743 10.7748L6.48132 12.2975L8.34706 9.89613C8.46743 9.73965 8.70817 9.73965 8.82854 9.90215L10.941 12.7188C11.0915 12.9174 10.9471 13.2003 10.7003 13.2003H3.4841C3.23132 13.2003 3.09289 12.9114 3.24937 12.7128L4.74799 10.7869C4.86234 10.6304 5.09104 10.6244 5.21743 10.7748Z" fill="currentColor"/>
        </svg>
    );
};

export default Library;
