import React, { useState, useEffect, useCallback } from 'react';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { getActivityList, ActivityRecord } from '../../features/activities/activitieSlice';
import { ConfigProvider, theme, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store'; // Adjust the import based on your store structure

import Button from '../../components/common/Button/Button';
import Divider from '../../components/common/Divider/Divider';
import TextField from '../../components/common/TextField/TextField';
import Text from '../../components/common/Text/Text';
import FilterPopup from './FilterPopup';
import CountPopup from './CountPopup';
import css from './Activities.module.scss';

const Activities: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { activityList, isLoading, isFail } = useSelector((state: RootState) => state.activities);
    const [filteredList, setFilteredList] = useState<ActivityRecord[]>([]);

    const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
    const [isOpenCount, setOpenCount] = useState<boolean>(false);
    const [nameFilter, setNameFilter] = useState<string>('');
    const [activityFilter, setActivityFilter] = useState<string>('');
    const [createdDateTo, setCreatedDateTo] = useState<string>('');
    const [createdDateFrom, setCreatedDateFrom] = useState<string>('');
    const [actionType, setActionType] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const reset = () => {
        const today = new Date();

        setCurrentPage(1);
        setActivityFilter('');
        setNameFilter('');
        setCreatedDateTo(today.toISOString().split('T')[0]);
        setCreatedDateFrom(today.toISOString().split('T')[0]);
    };

    // 기본 날짜 설정
    useEffect(() => {
        reset();
    }, []);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
                return;
            }
            if (e.key === 'ArrowLeft' && currentPage > 1) {
                setCurrentPage(prev => prev - 1);
            } else if (e.key === 'ArrowRight' && currentPage < Math.ceil(filteredList.length / 10)) {
                setCurrentPage(prev => prev + 1);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [currentPage, filteredList.length]);

    const refreshList = useCallback(() => {
        // check if createdDateFrom and createdDateTo are empty
        if (!createdDateFrom || !createdDateTo) {
            return;
        }
        dispatch(getActivityList({
            activity: '',
            from: createdDateFrom,
            to: createdDateTo,
        }));
    }, [dispatch, createdDateFrom, createdDateTo]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    const getFilteredList = useCallback(() => {
        let filtered = activityList;
        if (nameFilter) {
            filtered = filtered.filter(item => 
                item.name.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }
        if (activityFilter) {
            filtered = filtered.filter(item => 
                item.activity.toLowerCase().includes(activityFilter.toLowerCase())
            );
        }
        filtered = filtered.filter(item => item.isAdmin === actionType);
        return filtered;
    }, [activityList, nameFilter, activityFilter, actionType]);

    useEffect(() => {
        setFilteredList(getFilteredList());
    }, [getFilteredList]);

    const handleApplyFilter = useCallback((ev: {
        startDate: string;
        endDate: string;
    }) => {
        setCurrentPage(1);
        setCreatedDateFrom(ev.startDate);
        setCreatedDateTo(ev.endDate);
    }, []);

    const handleChangeSearch = (ev: any) => {
        setActivityFilter(ev.target?.value.trim() || '');
        setCurrentPage(1);
    };

    const handleChangeName = (ev: any) => {
        setNameFilter(ev.target?.value.trim() || '');
        setCurrentPage(1);
    };

    const columns: ColumnsType<ActivityRecord> = [
        {
            title: '번호',
            dataIndex: 'id',
            key: 'id',
            hidden: true
        },
        {
            title: '일시',
            dataIndex: 'date',
            key: 'id',
            width: 250,
        },
        {
            title: '계정명',
            dataIndex: 'name',
            key: 'id',
            width: 200,
        },
        {
            title: '액션타입',
            dataIndex: 'isAdmin',
            key: 'id',
            width: 100,
            render: (isAdmin: boolean) => isAdmin ? 'Admin' : 'User',
        },
        {
            title: '활동내역',
            dataIndex: 'activity',
            key: 'id',
        }
    ];

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <div className={css.activities}>
                <h2>로그 조회</h2>

                <div className={css.header}>
                    <div className={css.top}>
                        <Text>{isLoading ? '로딩 중 ...' : (isFail ? '로딩 실패 ‼️' : 'Tip: 좌우키 빠른이동 가능')}</Text>
                        <div className={css.right}>
                            <Button
                                icon="human"
                                type="line"
                                inline
                                priority="primary"
                                selected={!actionType}
                                onClick={() => setActionType(!actionType)}
                            >
                                {!actionType ? 'User' : 'Admin'}
                            </Button>
                            <TextField
                                className={css.search}
                                placeholder="계정명으로 검색"
                                value={nameFilter}
                                onChange={handleChangeName}
                            />
                            <TextField
                                className={css.search}
                                icon="search"
                                placeholder="ex. 활동내역"
                                value={activityFilter}
                                onChange={handleChangeSearch}
                            />
                            <Button icon="filter" onClick={() => setOpenFilter(true)} />
                            <Button icon="reset" priority="secondary" type="line" inline onClick={reset}>{'초기화'}</Button>

                            <Button icon="grid" priority="secondary" type="line" inline onClick={() => setOpenCount(true)}>간편통계</Button>

                            <Button icon="download" priority="secondary" type="line" inline onClick={() => {
                                const BOM = '\uFEFF';
                                const csv = BOM + filteredList.map(r => 
                                    [r.date, r.name, r.isAdmin? 'Admin' : 'User', r.activity]
                                        .map(value => `"${value?.toString().replace(/"/g, '""')}"`)
                                        .join(',')
                                ).join('\n');
                                const blob = new Blob([csv], { type: "text/csv;charset=utf-8-sig" });
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = 'activity_log.csv';
                                a.click();
                                URL.revokeObjectURL(url);
                            }}>{'CSV  '}</Button>

                        </div>
                    </div>

                </div>
                <Divider />
                <div>
                    <Table
                        columns={columns}
                        dataSource={filteredList}
                        loading={isLoading}
                        rowKey="id"
                        pagination={{
                            total: filteredList.length,
                            current: currentPage,
                            onChange: (page) => setCurrentPage(page)
                        }}
                        scroll={{ y: 55 * 10 }}
                    />
                </div>
                <FilterPopup
                    open={isOpenFilter}
                    onClose={() => setOpenFilter(false)}
                    onApply={handleApplyFilter}
                />
                <CountPopup
                    isOpen={isOpenCount}
                    onClose={() => setOpenCount(false)}
                    filteredList={filteredList}
                />
            </div>
        </ConfigProvider>
    );
};

export default Activities;
