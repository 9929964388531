const Activities = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.25 4.33008H13.25L18.5 9.43008V10.3301H16.75H16.5H13V6.03008H6.25V19.6301H16.75V18.3301H18.5V19.6301C18.5 20.5651 17.7125 21.3301 16.75 21.3301H6.24125C5.27875 21.3301 4.5 20.5651 4.5 19.6301L4.50875 6.03008C4.50875 5.09508 5.2875 4.33008 6.25 4.33008Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.5 11.3301C7.94772 11.3301 7.5 11.7778 7.5 12.3301V16.3301C7.5 16.8824 7.94772 17.3301 8.5 17.3301H19.3C19.8523 17.3301 20.3 16.8824 20.3 16.3301V12.3301C20.3 11.7778 19.8523 11.3301 19.3 11.3301H8.5ZM9.21141 12.6202V16.1602H11.5214V15.4802H10.0414V12.6202H9.21141ZM13.4488 16.2102C14.3988 16.2102 15.1338 15.5602 15.1388 14.3902C15.1338 13.2252 14.3988 12.5752 13.4488 12.5802C12.4988 12.5752 11.7738 13.2252 11.7688 14.3902C11.7738 15.5552 12.4988 16.2102 13.4488 16.2102ZM13.4488 15.4802C12.9388 15.4752 12.6238 15.0952 12.6188 14.3902C12.6238 13.6902 12.9388 13.3052 13.4488 13.3102C13.9688 13.3052 14.2838 13.6902 14.2888 14.3902C14.2838 15.0952 13.9688 15.4752 13.4488 15.4802ZM17.2774 13.3102C17.6324 13.3052 17.8674 13.4802 17.9474 13.7802H18.7974C18.7074 13.0752 18.0774 12.5752 17.2674 12.5802C16.3224 12.5752 15.5824 13.2402 15.5774 14.4002C15.5824 15.5152 16.2674 16.2102 17.2774 16.2102C18.1824 16.2102 18.8274 15.6452 18.8274 14.7002V14.2302H17.3174V14.8502H18.0274C18.0174 15.2352 17.7524 15.4752 17.2774 15.4802C16.7474 15.4752 16.4324 15.0802 16.4274 14.3902C16.4324 13.7002 16.7624 13.3052 17.2774 13.3102Z" fill="currentColor"/>
        </svg>
    );
}

export default Activities;
