import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { ConfigProvider, theme, Table, Upload, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import css from './ImagePanel.module.scss';
import { ImageRecordType, addBg, deleteBg, getBgList } from '../../features/image/imageSlice';
import Button from '../../components/common/Button/Button';
import Divider from '../../components/common/Divider/Divider';
import Popup from '../../components/common/Popup/Popup';
import TextField from '../../components/common/TextField/TextField';
import Text from '../../components/common/Text/Text';

const LabeledComponent = ({
    children,
    label
}: {
    children: JSX.Element;
    label: string;
}) => {
    return (
        <div className={css.labeledComponent}>
            <div className={css.label}>{label}</div>
            <div className={css.comp}>
                {children}
            </div>
        </div>
    );
}

const ImagePanel = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { bgList } = useSelector((state: RootState) => state.image);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [imageToDelete, setImageToDelete] = useState<ImageRecordType>();
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [uploadName, setUploadName] = useState('');
    const [uploadFile, setUploadFile] = useState<File>();
    const [fileList, setFileList] = useState<any[]>([]);
    const [fullScreenImage, setFullScreenImage] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            dispatch(getBgList());
            initialized.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
                return;
            }
            if (e.key === 'ArrowLeft' && currentPage > 1) {
                setCurrentPage(prev => prev - 1);
            } else if (e.key === 'ArrowRight' && currentPage < Math.ceil(bgList.length / 7)) {
                setCurrentPage(prev => prev + 1);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [currentPage, bgList.length]);

    const columns: ColumnsType<ImageRecordType> = [
        {
            title: '아이디',
            dataIndex: 'id',
            key: 'id',
            width: 250,
            hidden: true,
        },
        {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '이미지',
            dataIndex: 'src',
            key: 'src',
            render: (_, record) => (
                <img
                    src={record.src}
                    alt={record.name}
                    style={{ maxWidth: '100px', maxHeight: '100px', margin: '-10px 0', cursor: 'pointer' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setFullScreenImage(record.src);
                    }}
                />
            ),
        },
        {
            title: '액션',
            key: 'action',
            width: 100,
            render: (_, record) => (
                <Button priority="secondary" type="line" inline
                    onClick={() => handleDelete(record)}
                >
                    삭제
                </Button>
            ),
        }
    ];

    const handleAdd = () => {
        setUploadModalOpen(true);
    };

    const handleUploadApply = async () => {
        if (!uploadName || !uploadFile) {
            return;
        }
        await addBg(uploadName, uploadFile).then(() => {
            // 업로드 성공 시
        }).catch((err) => {
            // 업로드 실패 시
            console.log(err);
        }).finally(() => {
            setUploadModalOpen(false);
            setUploadName('');
            setUploadFile(undefined);
            setFileList([]);
            dispatch(getBgList());
        });
    };

    const handleDelete = (image: ImageRecordType) => {
        setImageToDelete(image);
        setDeleteConfirmOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!imageToDelete?.id) {
            return;
        }
        await deleteBg(imageToDelete.id).then(() => {
            setDeleteConfirmOpen(false);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setDeleteConfirmOpen(false);
            dispatch(getBgList());
        });
    };

    const beforeUpload = (file: File) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            message.error('이미지 파일만 업로드할 수 있습니다!');
            return false;
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('파일은 5MB보다 작아야 합니다!');
            return false;
        }
        setUploadFile(file);
        return false;
    };

    return (
        <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
            <div className={css.imagePanel}>
                <h2>배경 이미지 관리</h2>

                <div className={css.header}>
                    <div className={css.top}>
                        <Text>{'Tip: 좌우키 빠른이동 가능'}</Text>
                        <div className={css.right}>
                            <Button priority="secondary" type="line" inline onClick={handleAdd}>
                                {'이미지 추가'}
                            </Button>
                        </div>
                    </div>
                </div>
                <Divider />
                <Table
                    columns={columns}
                    dataSource={bgList}
                    rowKey="id"
                    pagination={{
                        total: bgList.length,
                        pageSize: 7,
                        current: currentPage,
                        onChange: (page) => setCurrentPage(page)
                    }}
                    // scroll={{ y: 80 * 7 }}
                    scroll={{ y: 100 * 7 }}
                />

                {/* 업로드 팝업 */}
                <Popup
                    open={uploadModalOpen}
                    onClose={() => setUploadModalOpen(false)}
                    content="이미지 추가"
                    contentSlot={
                        <div className={css.uploadForm}>
                            <div className={css.formWrapper}>
                                <LabeledComponent label="이미지 이름">
                                    <TextField
                                        type="text"
                                        placeholder="이미지 이름"
                                        value={uploadName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUploadName(e.target.value)}
                                    />
                                </LabeledComponent>
                                <LabeledComponent label="파일">
                                    <Upload
                                        accept="image/*"
                                        beforeUpload={beforeUpload}
                                        fileList={fileList}
                                        onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                                        maxCount={1}
                                        showUploadList={{
                                            showPreviewIcon: true,
                                            showRemoveIcon: true,
                                            showDownloadIcon: false
                                        }}
                                        listType="picture"
                                    >
                                        <Button type="line" priority="secondary">
                                            파일 선택
                                        </Button>
                                    </Upload>
                                </LabeledComponent>
                            </div>
                        </div>
                    }
                    buttons={
                        <>
                            <Button priority="secondary" type="line" onClick={() => {setUploadModalOpen(false); setUploadName(''); setUploadFile(undefined);}}>
                                취소
                            </Button>
                            <Button priority="primary" onClick={handleUploadApply}>
                                추가
                            </Button>
                        </>
                    }
                />

                {/* 삭제 확인 팝업 */}
                <Popup
                    open={deleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                    content="정말로 이 이미지를 삭제하시겠습니까?"
                    buttons={
                        <>
                            <Button priority="secondary" type="line" onClick={() => {setDeleteConfirmOpen(false); setImageToDelete(undefined);}}>
                                취소
                            </Button>
                            <Button priority="primary" onClick={handleDeleteConfirm}>
                                삭제
                            </Button>
                        </>
                    }
                />

                {fullScreenImage && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.9)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000,
                            cursor: 'pointer'
                        }}
                        onClick={() => setFullScreenImage(null)}
                    >
                        <img
                            src={fullScreenImage}
                            alt="Full screen preview"
                            style={{
                                maxWidth: '90%',
                                maxHeight: '90%',
                                objectFit: 'contain'
                            }}
                        />
                    </div>
                )}
            </div>
        </ConfigProvider>
    );
}

export default ImagePanel;
