import Button from '../Button/Button';
import css from './TimeInput.module.scss';

const TimeInput = ({
    onUp,
    onDown,
    onChange,
    onBlur,
    value,
}: {
    /** TimeInput onUp event */
    onUp: () => void;
    /** TimeInput onDown event */
    onDown: () => void;
    /** TimeInput onChange event */
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    /** TimeInput onBlur event */
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    /** TimeInput value */
    value: number;
}) => {
    return (
        <div className={css.timeInput}>
            <div className={css.up}>
                <Button icon="upTriangle" onClick={() => onUp()} />
            </div>
            <div className={css.timeValue}>
                <input type="text" value={value} onChange={onChange} onBlur={onBlur} />
            </div>
            <div className={css.down}>
                <Button icon="downTriangle" onClick={() => onDown()} />
            </div>
        </div>
    );
};

export default TimeInput;
