import classNames from 'classnames';
import Icon from '../common/Icon/Icon';
import css from './MenuItem.module.scss';

const MenuItem = ({
    className,
    id,
    icon,
    label,
    selected,
    onClick
}: {
    className?: string;
    id: string;
    icon: string;
    label: string;
    selected?: boolean;
    onClick?: ({selected}: {selected: string}) => void;
}) => {
    const componentCss = classNames(css.menuItem, className, {
        [css.selected]: selected
    });
    return (
        <div className={componentCss} onClick={() => onClick && onClick({selected: id})}>
            <Icon size="medium" selected={selected} clickable hoverable>{icon}</Icon>
            <div className={css.label}>{label}</div>
        </div>
    );
};

export default MenuItem;
