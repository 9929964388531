import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface menuType {
    selected: 'activities' | 'ai-human' | 'bg-image' | 'bg-music' | 'news-template'
}

const initialState: menuType = {
    selected: 'news-template'
};

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenu: (state: menuType, action: PayloadAction<'activities' | 'ai-human' | 'bg-image' | 'bg-music' | 'news-template'>) => {
            state.selected = action.payload;
        }
    },
    extraReducers: (builder) => {
        //
    }
});

export const {
    setMenu
} = menuSlice.actions;

export default menuSlice.reducer;
