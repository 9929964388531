import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CognitoIdentityProviderClient, ListUsersInGroupCommand } from "@aws-sdk/client-cognito-identity-provider";
import awsConfig from '../../amplifyconfiguration.json';

export const getAdmins = async () => {
    try {
        const client = new CognitoIdentityProviderClient({
            region: awsConfig.aws_cognito_region
        });

        const command = new ListUsersInGroupCommand({
            GroupName: "admin",
            UserPoolId: awsConfig.aws_user_pools_id,
            Limit: 60  // 한 번에 가져올 최대 사용자 수
        });

        const response = await client.send(command);
        return response.Users || [];
    } catch (error) {
        console.error('Failed to fetch admin users:', error);
        throw error;
    }
};

interface userDataType {
    userId: string;
}

const initialState: userDataType = {
    userId: 'test1' // TODO: fix
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserId: (state: userDataType, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        resetUserData: () => initialState
    },
    extraReducers: (builder) => {
        //
    }
});

export const {
    setUserId,
    resetUserData
} = userSlice.actions;

export default userSlice.reducer;
