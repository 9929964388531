const InfoDark = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 15 15" fill="none">
            <g clipPath="url(#clip0_1227_666)">
                <path d="M7.5 1C3.912 1 1 3.912 1 7.5C1 11.088 3.912 14 7.5 14C11.088 14 14 11.088 14 7.5C14 3.912 11.088 1 7.5 1Z" fill="#3F3F48"/>
                <path d="M7.5 0.5C3.63586 0.5 0.5 3.63586 0.5 7.5C0.5 11.3641 3.63586 14.5 7.5 14.5C11.3641 14.5 14.5 11.3641 14.5 7.5C14.5 3.63586 11.3641 0.5 7.5 0.5Z" stroke="black" strokeOpacity="0.2"/>
                <g filter="url(#filter0_d_1227_666)">
                    <path d="M6.85693 4.28516H8.14265V5.57087H6.85693V4.28516ZM6.85693 6.85658H8.14265V10.7137H6.85693V6.85658Z" fill="white"/>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_1227_666" x="2.85693" y="4.28516" width="9.28564" height="14.4287" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1227_666"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1227_666" result="shape"/>
                </filter>
                <clipPath id="clip0_1227_666">
                    <rect width="15" height="15" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default InfoDark;
