import React from 'react';
import { Modal, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ActivityRecord } from '../../features/activities/activitieSlice';

interface CountPopupProps {
    isOpen: boolean;
    onClose: () => void;
    filteredList: ActivityRecord[];
}

interface CountData {
    key: string;
    name: string;
    count: number;
}

const CountPopup: React.FC<CountPopupProps> = ({ isOpen, onClose, filteredList }) => {
    const countByName = () => {
        const counts = filteredList.reduce((acc: { [key: string]: number }, curr) => {
            const name = curr.name || 'Unknown';
            acc[name] = (acc[name] || 0) + 1;
            return acc;
        }, {});

        return Object.entries(counts).map(([name, count], index) => ({
            key: index.toString(),
            name,
            count,
        }));
    };

    const columns: ColumnsType<CountData> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a, b) => a.count - b.count,
        },
    ];

    return (
        <Modal
            title="간편통계"
            open={isOpen}
            onCancel={onClose}
            footer={null}
            width={600}
        >
            <Table
                columns={columns}
                dataSource={countByName()}
                pagination={false}
            />
        </Modal>
    );
};

export default CountPopup;
