import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { ConfigProvider, theme, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import css from './TemplatePanel.module.scss';
import {editTemplate, deleteTemplate, TemplateRecordType, addTemplate, getTemplateList } from '../../features/template/templateSlice';
import Button from '../../components/common/Button/Button';
import Divider from '../../components/common/Divider/Divider';
import EditTemplatePopup from './EditTemplatePopup';
import Popup from '../../components/common/Popup/Popup';


const TemplatePanel = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { templateList } = useSelector((state: RootState) => state.template);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [editRecord, setEditRecord] = useState<TemplateRecordType>();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState<TemplateRecordType>();

    useEffect(() => {
        dispatch(getTemplateList());
    }, [dispatch]);

    const columns: ColumnsType<TemplateRecordType> = [
        {
            title: '아이디',
            dataIndex: 'id',
            key: 'id',
            width: 280,
            hidden: true,
        },
        {
            title: '타이틀',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '브랜드',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: '클로징 멘트',
            dataIndex: 'closingComment',
            key: 'closingComment'
        },
        {
            title: '액션',
            key: 'action',
            width: 180,
            render: (_, record) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button priority="secondary" type="line" inline
                        onClick={() => handleEdit(record)}
                    >
                        수정
                    </Button>
                    <Button priority="secondary" type="line" inline
                        onClick={() => handleDelete(record)}
                    >
                        삭제
                    </Button>
                </div>
            ),
        }
    ];

    const handleEdit = (record: TemplateRecordType) => {
        setEditRecord(record);
        setShowEditPopup(true);
    };

    const handleAdd = () => {
        setEditRecord({
            id: '',
            title: '',
            brand: '',
            closingComment: ''
        });
        setShowEditPopup(true);
    };

    const handleAddApply = async (template: Omit<TemplateRecordType, 'id'>) => {
        // check if template is valid
        if (!template.title || !template.brand || !template.closingComment) {
            return;
        }
        await addTemplate(template).then((res) => {
            // 추가 성공 시
        }).catch((err) => {
            // 추가 실패 시
            console.log(err);
        }).finally(() => {
            // 다시 불러오기
            dispatch(getTemplateList());
        });
    };

    const handleEditApply = async (template: TemplateRecordType) => {
        // check if template.id is empty
        if (!template.id) {
            handleAddApply(template);
            return;
        }
        // check if template is valid
        if (!template.title || !template.brand || !template.closingComment) {
            return;
        }
        await editTemplate(template).then((res) => {
            // 수정 성공 시
        }).catch((err) => {
            // 수정 실패 시
            console.log(err);
        }).finally(() => {
            // 다시 불러오기
            dispatch(getTemplateList());
        });
    };

    const handleDelete = (template: TemplateRecordType) => {
        setTemplateToDelete(template);
        setDeleteConfirmOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!templateToDelete?.id) {
            return;
        }
        await deleteTemplate(templateToDelete.id).then((res) => {
            // 삭제 성공 시
        }).catch((err) => {
            // 삭제 실패 시
            console.log(err);
        }).finally(() => {
            // 다시 불러오기
            dispatch(getTemplateList());
            setDeleteConfirmOpen(false);
        });
    };

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <div className={css.templatePanel}>
                <h2>뉴스 템플릿 관리</h2>

                <div className={css.header}>
                    <div className={css.top}>
                        <div className={css.right}>
                            <Button priority="secondary" type="line" inline  onClick={handleAdd}>{'템플릿 추가'}</Button>
                        </div>
                    </div>
                </div>
                <Divider />
                <Table
                    columns={columns}
                    dataSource={templateList}
                    rowKey="id"
                    pagination={{
                        pageSize: 10,
                        total: templateList.length
                    }}
                />
                <EditTemplatePopup
                    open={showEditPopup}
                    onClose={() => setShowEditPopup(false)}
                    onApply={handleEditApply}
                    record={editRecord}
                />
                <Popup
                    open={deleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                    content="정말로 이 템플릿을 삭제하시겠습니까?"
                    buttons={
                        <>
                            <Button priority="secondary" type="line" onClick={() => setDeleteConfirmOpen(false)}>
                                취소
                            </Button>
                            <Button priority="primary" onClick={handleDeleteConfirm}>
                                삭제
                            </Button>
                        </>
                    }
                />
            </div>
        </ConfigProvider>
    );
}

export default TemplatePanel;
