const Delete = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14 14" fill="none">
            <circle cx="7" cy="7" r="7" fill="#3F3F48"/>
            <rect x="9.43311" y="4.06055" width="0.858268" height="7.72441" rx="0.429134" transform="rotate(45 9.43311 4.06055)" fill="white"/>
            <rect x="10.1006" y="9.4624" width="0.858268" height="7.72441" rx="0.429134" transform="rotate(135 10.1006 9.4624)" fill="white"/>
        </svg>
    );
};

export default Delete;
