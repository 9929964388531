const Pause = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ size } height={ size } viewBox="0 0 56 56" fill="none">
            <rect width="56" height="56" rx="28" fill="black" fillOpacity="0.4"/>
            <rect x="20" y="17" width="5" height="22" rx="1" stroke="white" strokeWidth="2"/>
            <rect x="31" y="17" width="5" height="22" rx="1" stroke="white" strokeWidth="2"/>
        </svg>
    );
};

export default Pause;
