import Add from '../icons/Add';
import AddImage from '../icons/AddImage';
import AIStar from '../icons/AIStar';
import ArticleInput from '../icons/ArticleInput';
import Background from '../icons/Background';
import Calendar from '../icons/Calendar';
import Check from '../icons/Check';
import Close from '../icons/Close';
import Construction from '../icons/Construction';
import Copy from '../icons/Copy';
import Activities from '../icons/Activities';
import Delete from '../icons/Delete';
import Down from '../icons/Down';
import Download from '../icons/Download';
import DownTriangle from '../icons/DownTriangle';
import Filter from '../icons/Filter';
import Flip from '../icons/Flip';
import FullScreen from '../icons/FullScreen';
import Grid from '../icons/Grid';
import Headline from '../icons/Headline';
import Human from '../icons/Human';
import Image from '../icons/Image';
import Info from '../icons/Info';
import Left from '../icons/Left';
import Library from '../icons/Library';
import List from '../icons/List';
import Logout from '../icons/Logout';
import Music from '../icons/Music';
import Pause from '../icons/Pause';
import PauseCircle from '../icons/PauseCircle';
import Play from '../icons/Play';
import PlayCircle from '../icons/PlayCircle';
import Right from '../icons/Right';
import Reset from '../icons/Reset';
import Resize from '../icons/Resize';
import Search from '../icons/Search';
import Template from '../icons/Template';
import Tilde from '../icons/Tilde';
import Trash from '../icons/Trash';
import Up from '../icons/Up';
import UpTriangle from '../icons/UpTriangle';
import Video from '../icons/Video';
import AddVideo from '../icons/AddVideo';
import InfoDark from '../icons/InfoDark';
import NewsTemplate from '../icons/NewsTemplate';

const iconList = {
    add: Add,
    addImage: AddImage,
    addVideo: AddVideo,
    aiStar: AIStar,
    articleInput: ArticleInput,
    background: Background,
    calendar: Calendar,
    check: Check,
    close: Close,
    construction: Construction,
    copy: Copy,
    activities: Activities,
    delete: Delete,
    down: Down,
    download: Download,
    downTriangle: DownTriangle,
    filter: Filter,
    flip: Flip,
    fullscreen: FullScreen,
    grid: Grid,
    headline: Headline,
    human: Human,
    image: Image,
    info: Info,
    infoDark: InfoDark,
    left: Left,
    library: Library,
    list: List,
    logout: Logout,
    music: Music,
    pause: Pause,
    pauseCircle: PauseCircle,
    play: Play,
    playCircle: PlayCircle,
    reset: Reset,
    resize: Resize,
    right: Right,
    search: Search,
    tamplete: Template,
    tilde: Tilde,
    trash: Trash,
    up: Up,
    upTriangle: UpTriangle,
    video: Video,
    newsTemplate: NewsTemplate,
};

export default iconList;
