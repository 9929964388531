import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import css from './TextField.module.scss';
import { DebounceInput } from 'react-debounce-input';

const TextField = (
    {
        className,
        placeholder = '',
        type = 'text',
        icon = '',
        readonly = false,
        value = '',
        multiLine = false,
        disabled = false,
        noMargin = false,
        onClear,
        onChange,
        onKeyUp,
        onAlertMaxLength
    }: {
        className?: string;
        placeholder?: string;
        icon?: string;
        readonly?: boolean;
        type?: 'search' | 'text' | 'number' | 'password';
        value?: string | number;
        multiLine?: boolean;
        disabled?: boolean;
        noMargin?: boolean;
        onClear?: () => void;
        onChange?: React.ChangeEventHandler | null;
        onKeyUp?: (ev: any) => void;
        onAlertMaxLength?: () => void;
    }
) => {
    const componentCss = classNames(css.textField, className, {
        [css.readonly]: readonly,
        [css.hasIcon]: !!icon,
        [css.multiLine]: multiLine,
        [css.disabled]: disabled,
        [css.noMargin]: noMargin
    });

    const onChangeInput = (ev: any) => {
        onChange && onChange(ev);
    };

    const onChangeTextarea = (ev: any) => {
        onChange && onChange(ev);
    }

    if (multiLine) {
        return (
            <div className={componentCss}>
                <textarea
                    placeholder={placeholder}
                    onChange={onChangeTextarea}
                    //defaultValue={value}
                    value={value}
                    inputMode="text"
                />
            </div>
        );
    }

    return (
        <div className={componentCss}>
            <DebounceInput
                id=""
                type={type}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onChange={onChangeInput}
                onKeyUp={onKeyUp}
            />
            {icon && <Icon className={css.icon} size="tiny">{icon}</Icon>}
        </div>
    );
};

export default TextField;
