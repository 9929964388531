import classNames from 'classnames';
import ReactPopup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';
import Icon from '../Icon/Icon';
import './Popup.css';

const Popup = ({
    children = null,    // For custom
    open = false,
    position = 'center center',
    content = '',
    contentSlot,
    buttons,
    useCloseButton,
    onClose
}: {
    children?: any;
    open?: boolean;
    position?: PopupPosition;
    content?: string;
    contentSlot?: JSX.Element;
    buttons?: JSX.Element;
    useCloseButton?: boolean;
    onClose?: (ev: any) => void;
}) => {
    const componentCss = classNames(
        'popup-content',
        position
    );

    return (
        <ReactPopup
            open={open}
            className={componentCss}
            onClose={onClose}
            position={position}
        >
            {useCloseButton ?
                <Icon className={'close'} onClick={onClose}>{'close'}</Icon> : null
            }
            {children ?
                children :
                <div className={'alert-format'}>
                    <div className={'content'}>{content}</div>
                    {contentSlot}
                    {
                        buttons ?
                            <div className={'buttons'}>
                                {buttons}
                            </div> : null
                    }
                </div>
            }
        </ReactPopup>
    );
};

export default Popup;
