import classNames from 'classnames';
import Image from '../common/Image/Image';
import css from './ReporterItem.module.scss'


const ReporterItem = ({
    src,
    rate = 1.0,
    title = '',
    thumbnail = '',
    selected,
    onClick
}: {
    src: string;
    rate?: number;
    title: string;
    thumbnail?: string;
    selected?: boolean;
    onClick?: () => void;
}) => {
    //const audioRef = useRef<HTMLAudioElement>(null);
    //const [currentTime, setCurrentTime] = useState(0);
    //const [duration, setDuration] = useState(0);

    //useEffect(() => {
    //    if (!isNaN(rate) && rate > 0) {
    //        (audioRef.current as any).playbackRate = rate;
    //    }
    //}, [rate]);

    //useEffect(() => {
    //    if (audioRef.current) {
    //        setDuration(audioRef.current.duration);
    //    }
    //}, [src]);

    //const handleTimeUpdate = () => {
    //    if (audioRef.current) {
    //        setCurrentTime(audioRef.current.currentTime);
    //    }
    //};

    return (
        <div
            className={classNames(css.reporterItem, {[css.selected]: selected})}
            onClick={onClick}
        >
            <div className={css.bg}>
                <Image src={thumbnail} />
            </div>
            <div className={css.title}>
                <span>{title}</span>
            </div>
        </div>
    );
};

export default ReporterItem;
