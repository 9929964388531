const Close = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <rect x="3" y="2" width="21.2134" height="1.41423" rx="0.707115" transform="rotate(45 3 2)" fill="white"/>
            <rect x="1.99951" y="17" width="21.2134" height="1.41423" rx="0.707115" transform="rotate(-45 1.99951 17)" fill="white"/>
        </svg>
    );
};

export default Close;
