const PauseCircle = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.1"/>
            <path d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12Z" fill="black"/>
            <path d="M12 1C5.92772 1 1 5.92772 1 12C1 18.0723 5.92772 23 12 23C18.0723 23 23 18.0723 23 12C23 5.92772 18.0723 1 12 1Z" stroke="white" strokeOpacity="0.1" strokeWidth="2"/>
            <path d="M10 16C10.55 16 11 15.55 11 15V9C11 8.45 10.55 8 10 8C9.45 8 9 8.45 9 9V15C9 15.55 9.45 16 10 16ZM14 16C14.55 16 15 15.55 15 15V9C15 8.45 14.55 8 14 8C13.45 8 13 8.45 13 9V15C13 15.55 13.45 16 14 16Z" fill="white"/>
        </svg>
    );
};

export default PauseCircle;
