const Play = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 56 56" fill="none">
            <rect width="56" height="56" rx="28" fill="black" fillOpacity="0.4"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.4 14.9551L42.5087 28.7092L21.4 40.935V14.9551ZM23.4 18.6453V37.4654L38.6913 28.6089L23.4 18.6453Z" fill="white"/>
        </svg>
    );
};

export default Play;

