import css from './Image.module.scss';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

const Image = ({
    src,
    noDelay
}: {
    src: string;
    noDelay?: boolean;
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [hasErr, setErr] = useState<boolean>(false);
    const delay = noDelay ? 0 : 1800;

    const handleErr = () => {
        const t = setTimeout(() => {
            setLoading(false);
            setErr(true)
        }, delay);
        return (() => {
            clearInterval(t);
        });
    };

    const handleLoad = () => {
        setLoading(false);
        setErr(false)
    };

    useEffect(() => {
        setErr(!src);
    }, [src]);

    return (
        <>
            {(loading || hasErr) && <div className={classNames(css.placeholder, {[css.err]: hasErr, [css.noDelay]: noDelay})} />}
            {!hasErr && <img
                alt=""
                src={src}
                onError={handleErr}
                onLoad={handleLoad}
            />
            }
        </>
    );
};

export default Image;
