const Template = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M10.3881 5.76195L13.8411 3.04889C14.3778 3.15889 14.7978 3.48889 14.9456 3.91056L10.2411 7.60469C10.4067 7.27112 10.5 6.89592 10.5 6.5C10.5 6.24338 10.4608 5.99546 10.3881 5.76195ZM8.70532 4.10193C8.48142 4.03562 8.24467 4 8 4C6.625 4 5.5 5.125 5.5 6.5C5.5 6.53962 5.50093 6.57903 5.50278 6.61821L1 10.1561V8.42667L7.90667 3H10.1078L8.70532 4.10193ZM6.71925 8.64464C7.09441 8.8701 7.53277 9 8 9C8.18497 9 8.36542 8.97964 8.53921 8.94105L7.48982 9.76507C5.72137 9.91797 3 10.8279 3 12.725V13.2906L2.15889 13.9511C1.89444 13.8961 1.65333 13.7861 1.45889 13.6394C1.27222 13.4867 1.13222 13.2972 1.06222 13.0894L6.71925 8.64464ZM13 14H13.4444C13.8722 14 14.2611 13.8656 14.5411 13.6394C14.8289 13.4194 15 13.1139 15 12.7778V11.5556L13 13.127V14ZM11.9783 10.9475L15 8.57333V6.84389L10.6607 10.2533C11.141 10.4339 11.5979 10.6643 11.9783 10.9475ZM1 4.22222C1 3.55 1.7 3 2.55556 3H4.11111L1 5.44444V4.22222Z" fill="currentColor"/>
            <path d="M8 4C6.625 4 5.5 5.125 5.5 6.5C5.5 7.875 6.625 9 8 9C9.375 9 10.5 7.875 10.5 6.5C10.5 5.125 9.375 4 8 4ZM8 7.33333C7.54167 7.33333 7.16667 6.95833 7.16667 6.5C7.16667 6.04167 7.54167 5.66667 8 5.66667C8.45833 5.66667 8.83333 6.04167 8.83333 6.5C8.83333 6.95833 8.45833 7.33333 8 7.33333ZM3 12.725V14H13V12.725C13 10.6417 9.69167 9.74167 8 9.74167C6.30833 9.74167 3 10.6333 3 12.725ZM4.925 12.3333C5.5 11.8667 6.90833 11.4 8 11.4C9.09167 11.4 10.5083 11.8667 11.075 12.3333H4.925Z" fill="currentColor"/>
        </svg>
    );
}

export default Template;
