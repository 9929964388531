import { useState } from 'react';
import Button from '../Button/Button';
import css from './TimeInput.module.scss';

const Meridiem  = ({
    onChange
}: {
    onChange: (value: string) => void;
}) => {
    const [value, setValue] = useState<"ante" | "post">("ante");

    const handleChange = () => {
        const newValue = value === "ante" ? "post" : "ante";
        setValue(newValue);
        onChange && onChange(newValue);
    };

    return (
        <div className={css.timeInput}>
            <div className={css.up}>
                <Button icon="upTriangle" onClick={handleChange} />
            </div>
            <div className={css.meridiemValue}>
                {value === "ante" ? "오전" : "오후"}
            </div>
            <div className={css.down}>
                <Button icon="downTriangle" onClick={handleChange} />
            </div>
        </div>
    );
};

export default Meridiem;
