import React from 'react';
import classNames from 'classnames';
import css from './Icon.module.scss';
import iconList from './iconList';

interface iconListType {
    [key: string]: (arg: any) => JSX.Element;
}
const list:iconListType = {...iconList};

const sizes = {
    mini: '1rem',
    tiny: '1.25rem',
    small: '1.5rem',
    medium: '2rem',
    large: '3.5rem'
};

const Icon = ({
    children,
    className,
    clickable = false,
    hoverable = false,
    selected = false,
    disabled = false,
    size = 'tiny',
    onClick
}: {
    children: string;
    className?: string;
    clickable?: boolean;
    hoverable?: boolean;
    selected?: boolean;
    disabled?: boolean;
    size?: 'mini' | 'tiny' | 'small' | 'medium' | 'large';
    onClick?: React.MouseEventHandler<HTMLElement>;
}) => {
    const componentCss = classNames(css.icon, className, {
        [css.hoverable]: hoverable,
        [css.clickable]: clickable || (onClick !== null && typeof onClick !== 'undefined'),
        [css.selected]: selected,
        [css.disabled]: disabled
    });

    const IconComponent = typeof children === 'string' ? list[children] : null;
    const sizeValue = sizes[size];
    return (
        <div className={componentCss} onClick={onClick}>
            {IconComponent && <IconComponent size={sizeValue} />}
        </div>
    );
};

export default Icon;
