import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GET, POST } from '../../utils/api';


export interface ImageRecordType {
    id: string;
    name: string;
    src: string;
}

interface ImageState {
    bgList: ImageRecordType[];
}


// Async Thunk 정의
export const getBgList = createAsyncThunk('/admin/get-bg-list', async () => {
    const res = await GET('/admin/get-bg-list');
    if (!res.errMsg && Array.isArray(res.bgList) && res.bgList) {
        return res.bgList;
    } else {
        return [];
    }
});

// API 함수들
export const addBg = async (name: string, file: File) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', file);

    return await POST('/admin/add-bg', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const deleteBg = async (id: string) => {
    const body = {
        id: id
    };
    return await POST('/admin/delete-bg', body);
};

const initialState: ImageState = {
    bgList: [],
};

export const imageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        setBgList: (state, action: PayloadAction<ImageRecordType[]>) => {
            state.bgList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBgList.fulfilled, (state, action) => {
            state.bgList = action.payload;
        })
    }
});

export const { setBgList } = imageSlice.actions;
export default imageSlice.reducer;
