import { configureStore, combineReducers } from '@reduxjs/toolkit';
import menuSlice from '../features/menu/menuSlice';
import humanSlice from '../features/human/humanSlice';
import templateSlice from '../features/template/templateSlice';
import activitiesSlice from '../features/activities/activitieSlice';
import userSlice from '../features/user/userSlice';
import imageSlice from '../features/image/imageSlice';
import musicSlice from '../features/music/musicSlice';
const rootReducer = combineReducers({
    menu: menuSlice,
    human: humanSlice,
    activities: activitiesSlice,
    template: templateSlice,
    user: userSlice,
    image: imageSlice,
    music: musicSlice
});
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
