import classNames from 'classnames';
import css from './Text.module.scss';

const Text = ({
    children,
    desc,
    required
}: {
    children: string;
    desc?: string;
    required?: boolean;
}) => {
    const componentCss = classNames(css.text, {
        [css.hasDesc]: !!desc
    })
    return (
        <div className={componentCss}>
            <div className={css.title}>
                {required ? <span>{'* '}</span> : null}
                {children}
            </div>
            {desc && <div className={css.desc}>{desc}</div>}
        </div>
    );
};

export default Text;
