// Fixed size icon
const AddImage = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M21.0002 16.3332C21.0002 17.4998 21.0002 23.3332 21.0002 23.3332H4.66683V6.99984H11.6668C12.8335 6.99984 12.8335 4.6665 11.6668 4.6665H4.66683C3.3835 4.6665 2.3335 5.7165 2.3335 6.99984V23.3332C2.3335 24.6165 3.3835 25.6665 4.66683 25.6665H21.0002C22.2835 25.6665 23.3335 24.6165 23.3335 23.3332C23.3335 23.3332 23.3335 17.4998 23.3335 16.3332C23.3335 15.1665 21.0002 15.1665 21.0002 16.3332ZM19.2502 20.9998H6.41683L9.62516 16.8815L11.9118 19.6348L15.1202 15.5048L19.2502 20.9998Z" fill="currentColor"/>
            <rect x="19.8335" y="1.1665" width="2.33333" height="11.6667" rx="1.16667" fill="currentColor"/>
            <rect x="26.8335" y="5.8335" width="2.33333" height="11.6667" rx="1.16667" transform="rotate(90 26.8335 5.8335)" fill="currentColor"/>
        </svg>
    );
};

export default AddImage;
