import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET } from '../../utils/api';

const adminActivity = ["배경 음악 추가", "배경 음악 삭제", "배경 이미지 추가", "배경 이미지 삭제", "AI Human 삭제"];

export interface ActivityRecord {
    id: string;
    name: string;
    date: string;
    activity: string;
    isAdmin?: boolean;
}

interface ActivitiesState {
    activityList: ActivityRecord[];
    isLoading: boolean;
    isFail: boolean;
}

export interface SearchParams {
    activity: string;
    from: string;
    to: string;
}

export const getActivityList = createAsyncThunk('/admin/get-activity-list', async ({activity, from, to}: SearchParams) => {
    const params = {
        activity: activity,
        startDate: from,
        endDate: to
    };
    // console.log('GET /admin/get-activity-list', params);
    const res = await GET('/admin/get-activity-list', params);

    function formatDate(date: string) {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const seconds = String(d.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    if (!res.errMsg && Array.isArray(res.activityList) && res.activityList) {
        res.activityList = res.activityList.map((activity: ActivityRecord, index: number) => ({
            ...activity,
            id: String(index + 1),
            date: formatDate(activity.date),
            isAdmin: adminActivity.some(adminAct => activity.activity.startsWith(adminAct))
        }));
        return res.activityList;
    } else {
        return [];
    }
});

const initialState: ActivitiesState = {
    activityList: [],
    isLoading: false,
    isFail: false
};

const activitiesSlice = createSlice({
    name: 'activities',
    initialState,
    reducers: {
        //
    },
    extraReducers: (builder) => {
        builder.addCase(getActivityList.fulfilled, (state, action) => {
            state.activityList = action.payload;
            state.isLoading = false;
            state.isFail = false;
        });
        builder.addCase(getActivityList.pending, (state) => {
            state.isLoading = true;
            state.isFail = false;
        });
        builder.addCase(getActivityList.rejected, (state) => {
            state.isLoading = false;
            state.isFail = true;
        });
    }
});

export default activitiesSlice.reducer;
