const AIStart = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path d="M11.6122 15.6125L10.8035 17.4657C10.4926 18.1781 9.50736 18.1781 9.1965 17.4657L8.38777 15.6125C7.66807 13.9632 6.37266 12.6505 4.75679 11.9328L2.53079 10.9442C1.82307 10.6299 1.82307 9.59978 2.53079 9.28547L4.68727 8.32772C6.34469 7.59163 7.66334 6.23018 8.37074 4.52467L9.18995 2.54969C9.49394 1.81677 10.506 1.81677 10.8101 2.54969L11.6293 4.52469C12.3367 6.23018 13.6553 7.59163 15.3127 8.32772L17.4692 9.28547C18.1769 9.59978 18.1769 10.6299 17.4692 10.9442L15.2432 11.9328C13.6273 12.6505 12.3319 13.9632 11.6122 15.6125Z" fill="url(#paint0_radial_1240_7520)"/>
            <path d="M19.1242 21.2337L18.9085 21.7284C18.7506 22.0905 18.2494 22.0905 18.0915 21.7284L17.8757 21.2337C17.4912 20.3516 16.7985 19.6493 15.9342 19.2651L15.2695 18.9696C14.9102 18.8099 14.9102 18.2872 15.2695 18.1274L15.8971 17.8484C16.7836 17.4544 17.4887 16.7261 17.8666 15.814L18.0882 15.2794C18.2426 14.9069 18.7574 14.9069 18.9118 15.2794L19.1333 15.814C19.5113 16.7261 20.2164 17.4544 21.103 17.8484L21.7304 18.1274C22.0899 18.2872 22.0899 18.8099 21.7304 18.9696L21.0657 19.2651C20.2014 19.6493 19.5088 20.3516 19.1242 21.2337Z" fill="url(#paint1_radial_1240_7520)"/>
            <defs>
                <radialGradient id="paint0_radial_1240_7520" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.68585 10.2975) rotate(-7.59859) scale(13.9444 13.9583)">
                    <stop stopColor="#0FDE17"/>
                    <stop offset="0.0001" stopColor="#55DF00"/>
                    <stop offset="0.317708" stopColor="#92EB39"/>
                    <stop offset="0.59375" stopColor="#BEFF6C"/>
                    <stop offset="1" stopColor="#FFF962"/>
                </radialGradient>
                <radialGradient id="paint1_radial_1240_7520" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.9634 16.907) rotate(43.0404) scale(5.80363 5.80667)">
                    <stop stopColor="#C2FF86"/>
                    <stop offset="0.484375" stopColor="#ACFF79"/>
                    <stop offset="1" stopColor="#CFFC70"/>
                </radialGradient>
            </defs>
        </svg>
    );
};

export default AIStart;
