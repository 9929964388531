import Icon from '../../components/common/Icon/Icon';
import css from './NotFound.module.scss'


const NotFound = () => {
    return (
        <div className={css.notFound}>
            <div className={css.text}>
                <Icon size={'medium'}>{'info'}</Icon>
                {'페이지를 찾을 수 없습니다.'}
            </div>
            <div className={css.subtext}>{'존재하지 않는 페이지입니다. URL을 다시 확인해주세요.'}</div>
        </div>
    );
};

export default NotFound;
