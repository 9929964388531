import Popup from '../../components/common/Popup/Popup';
import DatePicker from '../../components/common/DatePicker/DatePicker';
import Text from '../../components/common/Text/Text';
import Icon from '../../components/common/Icon/Icon';
import css from './FilterPopup.module.scss'
import Button from '../../components/common/Button/Button';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

const RangeButton = ({children, selected, onClick}: {
    children: string;
    selected?: boolean;
    onClick?: () => void;
}) => {
    return (
        <div className={classNames(css.rangeButton, {[css.selected]: selected})} onClick={onClick}>
            {children}
        </div>
    );
};

const FilterPopup = ({
    open,
    onClose,
    onApply
}: {
    open?: boolean;
    onClose: () => void;
    onApply: (ev: {
        startDate: string;
        endDate: string;
    }) => void;
}) => {
    const [range, setRange] = useState<'none' | '1day' | '1month' | '3months' | '6months' | '5years'>('1month');
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [startDate, setStartDate] = useState<Date>();

    useEffect(() => {
        let startDate = null;
        switch (range) {
        case '1day':
            startDate = endDate;
            break;
        case '1month':
            startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate(), endDate.getHours(), endDate.getMinutes());
            break;
        case '3months':
            startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 3, endDate.getDate(), endDate.getHours(), endDate.getMinutes());
            break;
        case '6months':
            startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 6, endDate.getDate(), endDate.getHours(), endDate.getMinutes());
            break;
        case '5years':
            startDate = new Date(endDate.getFullYear() - 5, endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes());
            break;
        default:
            startDate = startDate ? startDate : endDate;
        }
        setStartDate(startDate);
    }, [endDate, range]);

    const cancel = () => {
        onClose();
    };

    const apply = useCallback(() => {
        onClose();
        onApply({
            startDate: startDate? startDate.toISOString().split('T')[0] : endDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0]
        })
    }, [onClose, onApply, startDate, endDate]);

    const handleSelectRange = useCallback((selected: 'none' | '1day' | '1month' | '3months' | '6months' | '5years') => {
        setRange(selected === range ? 'none' : selected);
    }, [range]);

    return (
        <Popup
            open={open}
            position="center center"
            onClose={onClose}
        >
            <div className={css.filterPopup}>
                <div className={css.content}>
                    <div className={css.title}>{'검색 기간'}</div>
                    <div className={css.section}>
                        <Text>{'생성일자'}</Text>
                        <div className={css.range}>
                            <RangeButton selected={range === '1day'} onClick={() => handleSelectRange('1day')}>{'1일'}</RangeButton>
                            <RangeButton selected={range === '1month'} onClick={() => handleSelectRange('1month')}>{'1개월'}</RangeButton>
                            <RangeButton selected={range === '3months'} onClick={() => handleSelectRange('3months')}>{'3개월'}</RangeButton>
                            <RangeButton selected={range === '6months'} onClick={() => handleSelectRange('6months')}>{'6개월'}</RangeButton>
                            <RangeButton selected={range === '5years'} onClick={() => handleSelectRange('5years')}>{'최대(5년)'}</RangeButton>
                        </div>
                        <div className={css.createdDate}>
                            <DatePicker
                                date={startDate}
                                wrapperClassName={css.datePicker}
                                onChange={(date: Date) => setStartDate(date)}
                            />
                            <Icon size="tiny">{'tilde'}</Icon>
                            <DatePicker
                                placement="bottom-start"
                                date={endDate}
                                wrapperClassName={css.datePicker}
                                onChange={(date: Date) => setEndDate(date)}
                            />
                        </div>
                    </div>
                </div>
                <div className={css.buttons}>
                    <Button onClick={cancel} type="line">{'취소'}</Button>
                    <Button onClick={apply}>{'적용'}</Button>
                </div>
            </div>
        </Popup>
    );
};

export default FilterPopup;
