const Construction = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <circle cx="15" cy="10" r="1" fill="#5D6073"/>
            <circle cx="10" cy="10" r="1" fill="#5D6073"/>
            <circle cx="5" cy="10" r="1" fill="#5D6073"/>
        </svg>
    );
};

export default Construction;
