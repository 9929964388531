import classNames from 'classnames';
import Icon from '../Icon/Icon';
import css from './Button.module.scss';

const Button = ({
    children,
    className,
    disabled,
    selected,
    marked,
    icon,
    priority = 'primary',
    type = 'filled',
    fullWidth,
    inline,
    onClick,
}: {
    children?: string;
    className?: string;
    disabled?: boolean;
    selected?: boolean;
    marked?: boolean;
    icon?: string;
    priority?: 'primary' | 'secondary';
    type?: 'filled' | 'line';
    fullWidth?: boolean;
    inline?: boolean;
    onClick?: (ev: any) => void;
}) => {
    const iconOnly = !!icon && !children;
    const componentCss = classNames(css.button, className, {
        [css.disabled]: disabled,
        [css.selected]: selected,
        [css.marked]: marked,
        [css.hasIcon]: !!icon,
        [css.iconOnly]: iconOnly,
        [css.fullWidth]: fullWidth,
        [css.secondary]: priority === 'secondary',
        [css.inline]: inline,
        [css.line]: type === 'line'
    });
    return (
        <button className={componentCss} onClick={onClick}>
            {icon && <Icon className={css.icon} size="tiny">{icon}</Icon>}
            {children}
        </button>
    );
};

export default Button;
