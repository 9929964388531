const Add = ({ size }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <rect x="9" y="4" width="2" height="12" rx="1" fill="currentColor"/>
            <rect x="16" y="9" width="2" height="12" rx="1" transform="rotate(90 16 9)" fill="currentColor"/>
        </svg>
    );
};

export default Add;
